.textField {
  .inputClass {
    height: 32px;
    font-size: 13px;
    //padding: 0 11px;
  }
}

.form-group {
  .error {
    border: 1px solid #FF4848;
  }

  .error-label {
    color: #FF4848 !important;
  }
}
