.upload-image {

  .ant-upload-list-picture-card .ant-upload-list-item {
    height: 221% !important;
  }

  @media (min-width: 768px) {
    margin-right: -22px;
  }

  .ant-upload {
    width: 100%;
    text-align: center;
  }

  .ant-upload.ant-upload-select-picture-card {
    border: unset;
    width: 100%;
    border-radius: 4px;

  }

  .ant-upload-list-picture-container {
    width: 200px;
  }



  .ant-upload-list-picture-card .ant-upload-list-item-error {
    border: none;
    border-color: unset;
  }

  .ant-upload-list-picture-card .ant-upload-list-item-info {
    border: none;
    border-radius: 6px;
  }

  .ant-upload-list-picture-card-container {
    margin: unset;
    width: 50%;
  }

  .uploadImageBox {
    div {
      display: inline;
      margin-left: 8px;
      font-size: 17px;
      line-height: 20px;
      color: black !important;
    }

    width: 100%;
    height: 44px;
    background: #F2F2F2;
    border-radius: 4px;
  }

  .ant-upload.ant-upload-select-picture-card > .ant-upload {
    height: unset;
  }

  .ant-upload-list-picture-card-container {
    height: unset !important;
  }

  .ant-upload-list-picture-card .ant-upload-list-item {
    padding: unset;
    margin: 3px;
  }

  .ant-tooltip-inner, .ant-tooltip-arrow {
    display: none;
  }

  .ant-upload-list-picture-card-container .ant-upload-animate-inline-appear .ant-upload-animate-inline-appear-active .ant-upload-animate-inline {
    display: none;
  }

  .ant-upload.ant-upload-select-picture-card {
    height: unset;
  }
}

.uploadImageBox {
  //@media only screen and (min-width: 768px) {
  //  width: 424px;
  //}
  //width: 100%;
  background: #F2F2F2;
  border-radius: 4px;
}
