.dashboard-wrapper {
  .app-view-btn-header {
    display: none;
  }

  .app-view-btn-header-group {
    display: block;
  }

  @media only screen and (max-width: 768px) {
    .app-view-btn-header {
      display: block;
    }

    .app-view-btn-header-group {
      display: none;
    }
  }

  .view-app-card {
    position: relative;
    overflow: hidden;

    .round-div-parent {
      border-radius: 50%;
      border: 1px solid dimgray;

      .roundDiv-img {
        height: 150px;
        width: 150px;
        border-radius: 50%;
        border: 3px dashed #979595;

        img {
          border-radius: 50%;
        }
      }
    }

    .badge-on-top-right {
      position: absolute;
      right: -35px;
      top: 24px;
      rotate: 42deg;
      color: white;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      width: 151px;
      text-align: center;
    }

    .title {
      font-size: 20px;
      font-weight: 500;
    }

    .ant-collapse-header {
      cursor: default !important;
    }
  }
}
