.imageUpload {
  .roundDiv {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    background: #F2FAF6;

    img {
      border-radius: 50%;
    }
  }
}
