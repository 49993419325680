.uploadImageButton {

  .change {
    font-size: 15px;
    line-height: 18px;
    color: #BDBDBD;
    cursor: pointer;
    margin-top: 23px;
  }

  .uploadImageBox {
    @media only screen and (min-width: 768px) {
      //width: 424px;
    }

    .button-div {
      margin-top: 8px;
    }
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    height: 32px;
    background: #468af1;
    border-radius: 6px;
  }
}

.input-class {
  .inputClass {
    height: 32px !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
}
