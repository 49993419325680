.data-table {
  .ant-table-wrapper {
    .ant-table-cell.ant-table-selection-column {
      border-spacing: initial;
    }

    .ant-table-thead > tr > th {
      background: #7a7a7af7;
      font-weight: bold;
      text-align: center;
      font-size: 13px;
      color: #fff;
      padding-top: 10px;
      padding-bottom: 10px;
      border-bottom: none;
      border-bottom: 1px solid black;
    }

    .ant-table-thead > tr > th:hover {
      background: #fff;
      color: #7a7a7af7;
    }

    //.ant-table-selection-column, .ant-table-thead > tr > th :first-child {
    //  border-top-left-radius: 10px !important;
    //  border-bottom-left-radius: 10px !important;
    //}

    //.ant-table-thead > tr > th:last-child {
    //  border-top-right-radius: 10px !important;
    //  border-bottom-right-radius: 10px !important;
    //}

    .ant-table-tbody > tr > td {
      border-bottom: 0.5px solid #d7d7d7;
    }

    .custom-row-gap > td {
      background: #cfe7ff36;
      padding: 10px 0 10px 10px;
      border-bottom: 0 !important;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
    }

    .app-table-row > td:first-child {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    .custom-row-gap > td {
      background: #fcfdff;
      padding: 10px 0 10px 10px;
      border-bottom: 0 !important;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
    }

    .app-table-row {
      box-shadow: 1px 1px 10px -8px #000;
    }

    .app-table-row > td {
      background: #fcfdff;
      padding: 10px 0 10px 10px;
      border-bottom: 0 !important;
    }

    .custom-row-gap > td:first-child {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    .custom-row-gap > td:last-child {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    .app-table-row > td:last-child {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    .app-table-row > td:first-child {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      border-left: 4px solid #6777ef;
    }

    .highlight-bottom-border > td {
      border-bottom: solid 1px black !important
    }

    //
    //.highlight-top-border > td {
    //  border-top: solid 2px black !important;
    //  border-bottom: solid 2px #6d9eff !important
    //}

    //.ant-table-tbody > tr > td {
    //  color: #000000;
    //  border-bottom: none;
    //  font-size: 12px;
    //  font-weight: normal;
    //}

    //.data-row {
    //  padding-bottom: 5px;
    //  border-radius: 12px;
    //  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
    //}

    .active-row > td:first-child {
      border-left: 4px solid darkgreen !important;
    }

    //.active-row {
    //  background-color: coral !important;
    //  border-bottom: 14px;
    //  border-width: 3px;
    //  border-color: #5d3ebc;
    //  border-style: solid;
    //  border-radius: 12px;
    //}

    //.active-row:hover {
    //  //border-radius: 12px;
    //}

    //.ant-table {
    //  overflow: auto;
    //  padding: 0 5px;
    //  //background: #FFFFFF;
    //  //box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
    //
    //  table {
    //    border-spacing: 0px 10px;
    //    border-collapse: unset;
    //  }
    //
    //  .ant-switch-checked {
    //    background-color: #2DCC70;
    //  }
    //}

    //.ant-pagination {
    //  display: none;
    //}
  }

  .ant-table-content::-webkit-scrollbar {
    height: 10px;
  }

  .ant-table-content::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  .ant-table-content::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 20px;
    border: 1px solid #f1f1f1;
  }

  .ant-table-content::-webkit-scrollbar-thumb:hover {
    background-color: #555;
    border-radius: 20px;
    border: 1px solid #f1f1f1;
  }

  thead > tr > th {
    background: #dfe7ff !important;
    color: #363636 !important;
    border-bottom: 1px solid black;
  }

  .app-table {
    table {
      border-spacing: 0 10px;

      thead > tr > th {
        background: #dfe7ff !important;
        color: #363636 !important;
      }
    }
  }
}


.custom-table-home {
  table {
    border-spacing: 0 10px;
  }
}

.custom-pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  color: #828282;

  .ant-pagination {
    display: block !important;
    background: #FFFFFF;
    border: 1px solid #F2F2F2;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 0 7px;

    .ant-pagination-options {
      display: none !important;
    }

    .ant-pagination-prev, .ant-pagination-item, .ant-pagination-jump-next, .ant-pagination-next {
      margin-top: 5px;
      height: unset;
      margin-bottom: 5px;
      margin-right: 0;
      border: none;
      font-size: 15px;

      &.ant-pagination-item-active, &:hover {
        background: #2dcc7061;
        border-radius: 4px;
        color: #4f4f4f;

        a {
          color: black;
        }
      }
    }
  }

  @media only screen and (max-width: 425px) {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 10px;
  }
}

.action-menu {
  margin-top: 5px;
  border-radius: 50%;
  height: 36px;
  width: 36px;
  border: 1px solid rgb(217, 217, 217);
  padding: 4px 9px !important;
}
