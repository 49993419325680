.layout-main {
  transition: margin 0.3s;
  flex: 1 1;
  min-width: 0;
  min-height: 100vh;

  .layout-body {
    background: #ebedef;
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .layout-main {
      flex-basis: auto;
      flex-shrink: 0;
      flex-grow: 1;
      min-width: 0;
    }
  }
}

.layout-main {
  .custom-header {
    position: sticky;
    top: 0;
    //position: fixed;
    right: 0;
    left: 0;
    z-index: 1029;
    background: #fff;
    border-bottom: 1px solid #d8dbe0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-shrink: 0;
    min-height: 56px;
    //position: fixed !important;
    .create-btn {
      padding: 8px 12px !important;
    }

    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

    .user-profile {
      .dropdown-menu {
        width: 252px;
        box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
        border-radius: 4px;

        .username {
          padding: 0 10px;
          margin-bottom: 15px;
        }

        .logout {
          margin: 0 -8px;

          &:hover {
            background: #80808026;
          }
        }
      }
    }
  }

  .layout-display-center {
    justify-content: center;
    display: grid;
  }
}
