.switch {
  input[type=checkbox]:focus {
    background-color: transparent;
    border: 0px solid;
    height: 20px;
    width: 160px;
    color: #CCC;

  }
  .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #2DCC70 !important;
    border-color: #2DCC70 !important;
  }
}
