// Import CoreUI styles
@import "bootstrap/dist/css/bootstrap.min.css";
@import "dashboard";
@import "allApps";
@import "TheLayout";
@import "custom";
@import "dataTable";
@import "platform";
@import "viewapp";
@import "trashList";
@import "variable";
@import "consoleList";

.App {
  text-align: center;
}

.ant-switch-checked {
  background-color: #2dcc70 !important;
}

.border-1-grey {
  border: 1px solid #ced2d8;
}

.ant-radio-checked {
  .ant-radio-inner {
    background-color: #2dcc70 !important;
    border-color: #2dcc70 !important;
  }
}

#root {
  height: 100vh;
}

.filter-btn {
  background-color: white;
  margin-left: 20px;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  font-size: 15px;
  padding: 6px 14px;

  &:focus {
    outline: none;
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.border-radius-50 {
  border-radius: 50%;
}

.layout-main {
  .layout-body {
    background: #ebedef;
  }
}

.fade {
  height: 100%;
  width: 100%;
}

.min-height-0 {
  min-height: 0px !important;
}

.h-0 {
  height: 0px;
}

.h-44-px {
  height: 44px;
}

.border-radius-4 {
  border-radius: 4px !important;
}

.border-radius-8 {
  border-radius: 8px !important;
}

.common-link {
  font-size: 15px;
  color: #2D1FEC;
  cursor: pointer;
}

.cursor-default {
  cursor: default !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.recharts-wrapper {
  cursor: pointer !important;
}

.position-unset {
  position: unset !important;
}

.fit-to-content {
  width: fit-content !important;
}

.w-shadow-card {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
  border-radius: 6px !important;
}

.ant-card {
  .ant-card-body {
    padding: 15px;
    @media only screen and (max-width: 760px) {
      padding: 13px;
    }
  }
}

.roundImg {
  border-radius: 50%;

  img {
    border-radius: 50%;
  }
}

.align-center {
  align-items: center;
}

.height-inherit {
  height: inherit;
}

.text-gray {
  color: #828282 !important;
}

.text-gray2 {
  color: #4f4f4f !important;
}

.text-dark-green {
  color: #2dcc70 !important;
}

.text-gray3 {
  color: #333333;
}

.text-purple {
  color: #9b51e0;
}

.text-green {
  color: #27ae60;
}

.text-sky-blue {
  color: #2d9cdb !important;
}

.text-orange {
  color: #f2994a;
}

.text-black {
  color: #000000;
}

.color-red {
  color: orangered;
}

.text-right {
  text-align: right;
}

.top-80px {
  top: 80px !important;
}

.border-top-2 {
  border-top: 2px solid;
}

.border-color-green {
  border-color: #2dcc70;
}

.border-color-geekblue {
  border-color: #1d39c4;
}

.border-color-orangered {
  border-color: orangered;
}

.border-color-volcano {
  border-color: #eaa25a;
}

.border-color-red {
  border-color: #dc0000;
}

.border-top-1-green {
  border-top: 2px solid #00b470;
}

.border-top-1-purple {
  border-top: 2px solid purple;
}

.ant-dropdown-menu-title-content {
  display: flex;
  align-items: center;
}

.ant-dropdown-menu-title-content {
  display: flex;
  align-items: center;
}

.ant-dropdown-menu-item-only-child {
  padding: 5px 4px !important;
}

.zIndex-plus-40 {
  z-index: 1500 !important;
}

.m-height-32 {
  max-height: 32px !important;
}

.p-0-4-rem {
  padding: 0.4rem;
}

.modal-backdrop {
  z-index: 9999 !important;
}

.border-right-1-gray {
  border-right: 1px solid #dee2e6;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.console-create {
  .textField {
    .inputClass {
      height: 33px !important;
      font-size: 13px;
      //padding: 0 11px;
    }
  }
}
