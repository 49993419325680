.GreenButton {
  background: #468af1;
  color: #FFFFFF !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15) !important;
  border-radius: 4px;
  font-weight: bold !important;
  font-size: 15px;
  border: none !important;
  margin-bottom: 25px;
  text-align: center !important;
  padding: 11px 15.7px;
  &.btn:focus{
    box-shadow: unset !important;
  }
  &.btn:hover{
    background: #76a6f1;
    color: #FFFFFF !important;
  }
  &.btn:disabled{
    background: #76a6f1;
    color: #FFFFFF !important;
  }
  &.no-margin{
    margin-bottom: 0 !important;
  }
}
