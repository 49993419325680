.main-sidebar {
  .ps-active {
    //background-color: #f3f3f3;
    a:hover {
      background-color: #8aadff !important;
      color: rgb(86, 86, 86) !important;
    }

    .ps-menu-label {
      color: #000000 !important;
    }

    svg {
      fill: #000000 !important;

      .profile-icon {
        fill: #000000 !important;
      }

      .platform-icon {
        fill: #000000 !important;
      }
    }
  }

  .sidebar-header {
    a:hover {
      background: transparent !important;
    }

    .hover-transparent:hover {
      background-color: rgba(255, 255, 255, 0.08);
      color: #ffffff;
    }
  }

  .ps-menuitem-root {
    .ps-menu-label {
      color: gray;
    }

    svg {
      fill: gray;

      .platform-icon {
        fill: gray;
      }

      .profile-icon {
        fill: gray;
      }
    }
  }

  ::-webkit-scrollbar {
    width: 3px;
  }

  ::-webkit-scrollbar-track {
    background: #fffefe;
  }

  ::-webkit-scrollbar-thumb {
    background: #838181;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
