.fade {
  height: 100%;
  width: 100%;
}

.min-height-0 {
  min-height: 0px !important;
}

.border-radius-4 {
  border-radius: 4px !important;
}

.border-radius-8 {
  border-radius: 8px !important;
}

.word-wrap {
  word-wrap: break-word;
}

.common-link {
  font-size: 15px;
  color: #2D1FEC;
  cursor: pointer;
}

.cursor-default {
  cursor: default !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.recharts-wrapper {
  cursor: pointer !important;
}

.position-unset {
  position: unset !important;
}

.fit-to-content {
  width: fit-content !important;
}

.w-shadow-card {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
  border-radius: 6px !important;
}

.ant-card {
  .ant-card-body {
    padding: 20px;

    @media only screen and (max-width: 760px) {
      padding: 13px;
    }
  }
}

.slick-next:before,
.slick-prev:before {
  color: #2eb85c !important;
}

.align-center {
  align-items: center;
}

.height-inherit {
  height: inherit;
}

.text-gray {
  color: #828282 !important;
}

.text-gray2 {
  color: #4f4f4f !important;
}

.text-dark-green {
  color: #2dcc70 !important;
}

.text-gray3 {
  color: #333333;
}

.text-purple {
  color: #9b51e0;
}

.text-green {
  color: #27ae60;
}

.text-sky-blue {
  color: #2d9cdb;
}

.text-orange {
  color: #f2994a;
}

.text-black {
  color: #000000;
}

.text-right {
  text-align: right;
}

.overflow-y-scroll-auto {
  overflow-y: auto !important;
  overflow-x: hidden !important;
}

.overflow-x-scroll-auto {
  overflow-y: hidden !important;
  overflow-x: auto !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
  overflow-x: hidden !important;
}

.word-wrap {
  word-wrap: break-word;
}

.h-45 {
  height: 45px !important;
}

.h-625 {
  height: 625px !important;
}

.h-max-300 {
  max-height: 300px;
}

.h-max-625 {
  max-height: 625px;
}

.h-max-700 {
  max-height: 650px !important;
}

.h-max-1500 {
  max-height: 1500px !important;
}

.fw-700 {
  font-weight: 700;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.fs-5 {
  font-size: 7px !important;
}

.fs-11 {
  font-size: 11px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-13 {
  font-size: 13px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-15 {
  font-size: 15px !important;
}

.fs-16 {
  font-size: 16px;
}

.fs-17 {
  font-size: 17px;
}

.fs-18 {
  font-size: 18px;
}

.fs-17 {
  font-size: 17px;
}

.fs-18 {
  font-size: 18px;
}

.fs-18 {
  font-size: 18px;
}

.fs-18 {
  font-size: 18px;
}

.fs-20 {
  font-size: 20px;
}

.fs-33 {
  font-size: 33px;
}

.fs-48 {
  font-size: 48px;
}

.fw-bold {
  font-weight: bold;
}

.fw-normal {
  font-weight: normal;
}

.gray-divider {
  border: 1px solid gray;
}

.pl-50 {
  padding-left: 50px;
}

.mr-20 {
  margin-right: 20px;
}

.m-25 {
  margin: 25px;
}

.mt-10 {
  margin-top: 10px !important;
}

.ml-13 {
  margin-left: 13px;
}

.ml-15 {
  margin-left: 0px;
}

.ml-16 {
  margin-left: 16px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-40 {
  margin-left: 40px;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-6 {
  margin-bottom: 6px;
}

.mb-8 {
  margin-bottom: 8px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-17 {
  margin-bottom: 17px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mt-7 {
  margin-top: 7px;
}

.mt-12 {
  margin-top: 12px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-16 {
  margin-top: 16px;
}

.mt-19 {
  margin-top: 19px;
}

.mt-22 {
  margin-bottom: 22px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-100 {
  margin-top: 100px;
}

.height-100vh {
  height: 95vh;
}

.height-40 {
  height: 40px;
}

.height-44 {
  height: 44px;
}

.height-90 {
  height: 90%;
}

.height-32rem {
  height: 32rem;
  text-decoration-color: #768192;
}

.boxheight-40 {
  height: 40px;
}

.fw-bold {
  font-weight: bold;
}

.ml-10 {
  margin-left: 10px;
}

.ml-13 {
  margin-left: 13px;
}

.ml-15 {
  margin-left: 15px;
}

.error-label {
  color: #FF4848 !important;
}


.ml-16 {
  margin-left: 16px;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-6 {
  margin-bottom: 6px;
}

.mb-17 {
  margin-bottom: 17px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mt-7 {
  margin-top: 7px;
}

.mt-12 {
  margin-top: 12px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-16 {
  margin-top: 16px;
}

.mt-19 {
  margin-top: 19px;
}

.mt-22 {
  margin-bottom: 22px;
}

.mt-30 {
  margin-top: 30px;
}

.mr-17 {
  margin-right: 17px;
}

.pl-23 {
  padding-left: 23px;
}

.height-100vh {
  height: 95vh;
}

.boxheight-40 {
  height: 40px;
}

.fw-bold {
  font-weight: bold;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-6 {
  margin-bottom: 6px;
}

.mb-17 {
  margin-bottom: 17px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mt-1px {
  margin-top: 1px;
}

.mt-7 {
  margin-top: 7px;
}

.mt-12 {
  margin-top: 12px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-16 {
  margin-top: 16px;
}

.mt-19 {
  margin-top: 19px;
}

.mt-22 {
  margin-bottom: 22px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-20 {
  margin-top: 20px;
}

.mr-13 {
  margin-right: 13px;
}

.mr-17 {
  margin-right: 17px;
}

.height-100vh {
  height: 95vh;
}

.boxheight-40 {
  height: 40px;
}

.fw-bold {
  font-weight: bold;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-6 {
  margin-bottom: 6px;
}

.mb-17 {
  margin-bottom: 17px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mt-7 {
  margin-top: 7px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-16 {
  margin-top: 16px;
}

.mt-22 {
  margin-bottom: 22px;
}

.mt-30 {
  margin-top: 30px;
}

.height-100vh {
  height: 95vh;
}

.boxheight-40 {
  height: 40px;
}

hr {
  opacity: 0.2;
  border-bottom: 1px solid #000000;
  margin: 0;
}

.max-width-100 {
  max-width: 100%;
}

.max-w-200 {
  max-width: 200px;
}

.max-w-248 {
  max-width: 248px;
}

.max-w-300 {
  max-width: 300px;
}

.max-w-500 {
  max-width: 500px;
}

.w-70 {
  width: 86px;
}

.w-98 {
  width: 98% !important;
}

.w-99 {
  width: 99% !important;
}

.w-99-54 {
  width: 99.54% !important;
}

.w-170 {
  width: 170px;
}

.w-178 {
  width: 178px;
}

.w-200 {
  width: 200px;
}

.w-110 {
  width: 110px;
}

.w-160 {
  width: 160px;
}

.w-271 {
  width: 271px;
}

.w-241 {
  width: 241px;
}

.w-292 {
  width: 292px;
}

.w-354 {
  width: 354px;
}

.w-100 {
  width: 100%;
}

.w-368 {
  width: 368px;
}

.w-139 {
  width: 139px;
}

.lh-20 {
  line-height: 20px;
}

.lh-23 {
  line-height: 23px;
}

.lh-18 {
  line-height: 18px;
}

.lh-20 {
  line-height: 20px;
}

.lh-23 {
  line-height: 23px;
}

.lh-18 {
  line-height: 18px;
}

.round-50 {
  height: 50px;
  width: 50px;
  border-radius: 50%;

  img {
    border-radius: 50%;
  }
}

.round-24 {
  height: 24px;
  width: 24px;
  border-radius: 50%;

  img {
    height: 24px;
    width: 24px;
    border-radius: 50%;
  }
}

.custom-black-color:hover {
  color: black !important;
}

.color-red {
  color: orangered;
}

.radiant-bg-key {
  //background: rgb(2,0,36);
  background: linear-gradient(90deg, rgb(255 95 95) 0%, #bd58a3 50%, #0f7ef5 100%) !important;
}

.bg-purple-gradient {
  background: linear-gradient(#8E54E9 3%, #4776E6 100%) !important;
}

.bg-blue-gradient {
  background: linear-gradient(#45b2ff 2%, #007dc7 100%) !important;
}

.bg-orangered-gradient {
  background: linear-gradient(90deg, #ff8803 20%, #ffad0d 100%) !important;
}

.border-radius-0 {
  border-radius: 0;
}

.bg-orange {
  background: orange !important;
}

.bg-orange:hover {
  background: #e9b24d !important;
}

.bg-orangered {
  background: orangered !important;
}

.bg-orangered-light {
  background: rgb(251 137 94) !important
}

.bg-volcano {
  background: #eaa25a !important;
}

.bg-red {
  background: #dc0000;
}

.bg-geekblue {
  background: #1d39c4;
}

.lh-20 {
  line-height: 20px;
}

.lh-23 {
  line-height: 23px;
}

.lh-18 {
  line-height: 18px;
}

.btn-signIn {
  width: 320px;
  height: 44px;
  margin-top: 34px;
}

.lh-20 {
  line-height: 20px;
}

.lh-23 {
  line-height: 23px;
}

.lh-18 {
  line-height: 18px;
}

.btn-signIn {
  width: 320px;
  height: 44px;
  margin-top: 34px;
}

.d-flex {
  display: flex !important;
}

.d-none {
  display: none !important;
}

.d-block {
  display: block !important;
}

.justify-content-flex-end {
  justify-content: flex-end;
}

.black-14-normal {
  font-family: Roboto;
  font-size: 14px;
  color: #000000;
}

.black-15-normal {
  font-family: Roboto;
  font-size: 15px;
  color: #000000;
}

.black-15-bold {
  font-family: Roboto;
  font-weight: bold;
  font-size: 15px;
  color: #000000;
}

.black-bold {
  font-family: Roboto;
  font-weight: bold;
  font-size: 12px;
  color: #464646;
}

.gray-normal {
  font-size: 12px;
  font-family: Roboto;
  color: #4f4f4f;
}

.gray-bold {
  font-family: Roboto;
  font-weight: 500;
  font-size: 12px;
  color: #4f4f4f;
}

.gray-16 {
  font-family: Roboto !important;
  font-size: 16px !important;
  color: #828282 !important;
}

.dark-blue-bold {
  color: #202e55;
  font-weight: 500;
}

.dark-blue-17-bold {
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  color: #202e55;
}

.gray-17 {
  font-family: Roboto;
  font-size: 17px;
  color: #4f4f4f;
}

.text-wrap-left {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pagination-div {
  padding: 4px 0px;
  width: fit-content;
  background: #ffffff;
  border: 1px solid #f2f2f2;
  box-sizing: border-box;
  border-radius: 4px;
}

.border-radius-10 {
  border-radius: 10px !important;
}

.border-radius-12 {
  border-radius: 12px !important;
}

.border-radius-13 {
  border-radius: 13px !important;
}

.border-radius-15 {
  border-radius: 15px !important;
}

.top-21 {
  top: 21px;
}

.top-18 {
  top: 18%;
}

.bottom-19 {
  bottom: 19px;
}

.right-0 {
  right: 0;
}

.right-5 {
  right: 5px;
}

.top-5 {
  top: 5px;
}

.right-24 {
  right: 24px;
}

.right-39 {
  right: 39px;
}

.right-54 {
  right: 54px;
}

.right-69 {
  right: 69px;
}

.disabled-button {
  cursor: not-allowed !important;
}

.plr-25 {
  padding: 0 25px;
}

.ant-message {
  z-index: 1050 !important;
}

.ant-modal-wrap {
  z-index: 11111111111 !important;
}

.ant-image-preview-operations {
  background-color: black !important;
}

.close-eye-icon {
  //background-image: url("../assets/icons/Close_eye.svg");
  height: 29px;
  width: 29px;
  background-size: 70% 70%;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.3s;
}

.bg-light-purple {
  background: #6776ed !important;
}

.bg-lighter-purple {
  background: rgba(124, 133, 231, 0.73) !important;
}

.bg-light-orange {
  background: #f79627 !important;
}

.bg-light-cyan-blue {
  background: #E8F1F8 !important;
}

.bg-light-blue {
  background: #38a5f6 !important;
}

.bg-light-sky:hover {
  background: #00adff12;
}

.bg-light-sky-blue {
  background: #83cefc !important;
}

.bg-light-purple .bg-orange {
  background: #FFA500;
}

.bg-green-button {
  background: #2dcc70;
}

.gap-5-px {
  gap: 5px !important;
}

.gap-10 {
  gap: 10px !important;
}

.gap-20 {
  gap: 20px !important;
}

.rotate-90 {
  rotate: 90deg;
}

.border-left-1-green-radius {
  border-left: 1px solid #2dcc70;
}

.w-24 {
  width: 24px;
}

.mt-4-px {
  margin-top: 4px;
}

.border-color-gray {
  border-color: gray;
}

.border-color-rebeccapurple {
  border-color: rebeccapurple;
}

.border-top-bottom-1-gray {
  border-top: 1px solid gray;
  border-bottom: 1px solid gray;
}


.ripple-button {
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.ripple-button:hover:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
  pointer-events: none;
  animation: ripple 0.5s linear;
}

.ant-avatar-string {
  font-size: 20px;
}

@keyframes ripple {
  from {
    width: 0;
    height: 0;
    opacity: 1;
  }
  to {
    width: 200px; /* Adjust the size of the ripple effect here */
    height: 200px; /* Adjust the size of the ripple effect here */
    opacity: 0;
  }
}
