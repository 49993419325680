.tabBar {
  background: #FFFFFF;
  //height: 40px;

  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding-left: 20px;
  padding-right: 20px;
  @media only screen and (min-width: 768px) {
    width: fit-content;
    min-width: 650px;
    padding-left: 42px;
    padding-right: 42px;

  }

  .button {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    color: #4F4F4F;
  }

  .pmt-5px {
    padding-top: 5px !important;
    margin-top: 5px !important;
  }

  .button-active {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    height: 30px;
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 3px 11px;
    background: #2DCC70 !important;
    border-radius: 4px;
    color: #FFFFFF;
  }
}

.simpleTab {
  background: none !important;
  border-radius: 0 !important;
  box-shadow: none !important;

  @media only screen and (min-width: 768px) {
    width: fit-content;
    min-width: 600px;
  }

  .nav-item {
    border: 1px solid #efefef;
    margin-right: 10px;
  }

  .nav-item-active {

    margin-right: 10px;
    background-color: #E8F1F8 !important;
  }

  .button {
    color: #000000 !important;
  }

  .button-active {
    color: #000000 !important;
    background: none !important;
  }
}

//
//.tabBar {
//  background: #FFFFFF;
//  //height: 40px;
//  overflow-x: scroll;
//  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
//  border-radius: 4px;
//  padding-left: 20px;
//  padding-right: 20px;
//  @media only screen and (min-width: 768px) {
//    width: fit-content;
//    min-width: 650px;
//    padding-left: 42px;
//    padding-right: 42px;
//  }
//
//  ul {
//    flex-wrap: nowrap;
//  }
//
//  .button {
//    font-style: normal;
//    font-weight: normal;
//    font-size: 15px;
//    line-height: 20px;
//    text-align: center;
//    color: #4F4F4F;
//  }
//
//  .pmt-5px {
//    padding-top: 5px !important;
//    margin-top: 5px !important;
//  }
//
//  .button-active {
//    font-style: normal;
//    font-weight: 500;
//    font-size: 15px;
//    line-height: 20px;
//    text-align: center;
//    height: 30px;
//    margin-top: 5px;
//    margin-bottom: 5px;
//    padding: 3px 11px;
//    background: #2DCC70;
//    border-radius: 4px;
//    color: #FFFFFF;
//  }
//}
//
//.tabBar::-webkit-scrollbar {
//  width: 0 !important
//}
