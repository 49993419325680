.dashboard-wrapper {
  .main-card {
    .ant-card-body {
      padding: 10px !important;
    }

    //box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.16);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px !important;

    .work-card {
      color: white;
      display: flex;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px !important;
      border-radius: 6px;

      .bg-circle-img {
        background: #ffffff47;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          max-width: 25px;
        }
      }

      .title {
        font-weight: bold;
        font-size: 30px;
        color: #FFFFFF;
      }

      .sub-title {
        font-size: 13px;
        color: #FFFFFF;
        opacity: 0.8;
      }

      .ant-card-body {
        width: 100%;
      }

      h3 {
        color: white;
      }

      .ant-card-body {
        padding: 10px !important;
      }

      &.display-card-active {
        background: #468af1;
      }

      &.display-card-suspended {
        background: #f69252;
      }
    }
  }

  //.filter-btn {
  //  min-width: 94px;
  //  max-width: 94px;
  //  background-color: white;
  //  //margin-left: 20px;
  //  border-radius: 4px;
  //  border: 1px solid #e0e0e0;
  //  box-sizing: border-box;
  //  font-size: 15px;
  //  padding: 6px 14px;
  //  @media only screen and (max-width: 425px) {
  //    margin-left: 0 !important;
  //  }
  //  &:hover {
  //    background-color: #ecf0e7;
  //  }
  //
  //  &:focus {
  //    outline: none;
  //  }
  //}
  .custom-grid {
    .ant-pagination {
      display: none !important;
    }
  }

  .section-title {
    font-weight: bold;
    font-size: 15px;
    color: #000000;
    opacity: 0.8;
  }

  .custom-pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 15px;
    color: #828282;

    .ant-pagination {
      display: block !important;
      background: #FFFFFF;
      border: 1px solid #F2F2F2;
      box-sizing: border-box;
      border-radius: 4px;
      padding: 0 7px;

      .ant-pagination-options {
        display: none !important;
      }

      .ant-pagination-prev, .ant-pagination-item, .ant-pagination-jump-next, .ant-pagination-next {
        margin-top: 5px;
        height: unset;
        margin-bottom: 5px;
        margin-right: 0;
        border: none;
        font-size: 15px;

        &.ant-pagination-item-active, &:hover {
          background: #2dcc7061;
          border-radius: 4px;
          color: #4f4f4f;

          a {
            color: black;
          }
        }
      }
    }

    @media only screen and (max-width: 425px) {
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      gap: 10px;
    }
  }
}

.outer-key-card {
  border-radius: 4px;

  .ant-card-body {
    padding: 7px !important;

    .fix-btn {
      padding: 2px 7px;
      border-radius: 2px;
    }
  }
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.top-label-card {
  position: relative;
  top: 0;
  transition: top ease 0.5s;

  .display-card-image {
    animation: zoom-in-zoom-out 1.5s ease infinite;
  }
}

.top-label-card:hover {
  top: -5px;
}