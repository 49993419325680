.SelectMultiple {
  .form-group {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';

    .ant-select {
      &:hover, &:focus, &:active {
        border-color: #2D1FEC;
        box-shadow: inset 0 -1px 0 #ddd;
        outline: none;
      }

      .ant-select-selector {
        max-height: 40px;

        .ant-select-selection-overflow {
          .ant-select-selection-overflow-item {
            //margin-right: 12px;

            .ant-select-selection-item {
              height: 26px;
              padding-left: 0;
              background-color: #F2FAF6;
              font-size: 17px;
              display: flex;
              gap: 5px;

              .ant-select-selection-item-content {
                order: 2;
              }

              .anticon {
                display: inline-flex;
                margin-left: 5px;
              }
            }
          }
        }
      }

      .ant-select-arrow, .ant-select-clear {
        margin-right: 4px;
      }
    }

  }
}
