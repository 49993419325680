.platform {
  .platform-img {
    max-height: 150px;
    max-width: 150px;
  }

  .user-card {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
}
