.loading{
  position: fixed;
  z-index: 999;
  height: 100%;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #ffffff85;

  .spin {
    position: fixed;
    top: 50%;
    left: 50%;
    
  }

  .no-left {
    left: 50% !important;
  }
}
