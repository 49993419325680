.selectMenu {
  .form-group {
    .form-control {
      -webkit-appearance: none;
      -moz-appearance: none;
      text-indent: 1px;
      text-overflow: '';
      &:hover, &:focus, &:active {
        box-shadow: inset 0 -1px 0 #ddd;
        outline: none;
      }
    }
    option:disabled {
      background: #afafaf !important;
      color: #ffffff !important;
    }
    .down-arrow {
      position: absolute;
      top: 6px;
      right: 15px;
    }
  }
}
