.lightBlueButton {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15) !important;
  border-radius: 4px !important;
  font-weight: bold !important;
  font-size: 15px !important;
  border: none !important;
  text-align: center !important;
  padding: 11px 15.7px;
  color: #202E55 !important;
  margin-bottom: 25px;
  &.btn:focus{ box-shadow: unset !important;}
  &.no-margin{
    margin-bottom: 0 !important;
  }
}
