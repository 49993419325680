.reports {
  .prepend-box {
    @media only screen and (max-width: 1600px) {
      width: 100% !important;
    }

    .ant-collapse-header-text {
      width: 100%;
    }

    .mobile-filter {
      display: none;
    }

    @media only screen and (max-width: 950px) {
      .web-filter {
        display: none !important;
      }
      .mobile-filter {
        display: block !important;
      }
      .column-data {
        flex-direction: column !important;

        .full-width {
          max-width: 100% !important;
        }
      }
    }

    .ant-collapse-item {
      border-bottom: 0px !important;

      .ant-collapse-header {
        width: 100%;
        display: inline-flex;
        align-items: center;
        //padding: 1px 16px !important;

        .filter-icon {
          height: 13px;
          width: 16px;
        }
      }

      .ant-collapse-content {
        border: 0 !important;

        //.ant-table-thead > tr > th {
        //  background: none !important;
        //  border-bottom: 0 !important;
        //}
        //
        //.ant-table-cell {
        //  font-size: 13px;
        //  border-bottom: 0 !important;
        //}
        //
        //.ant-table-cell:hover {
        //  background: none !important;
        //  border-bottom: 0 !important;
        //}
      }
    }
  }

  .filter-card {
    .ant-card-body {
      padding: 0 !important;

      .inputClass {
        height: 32px !important;
        font-size: 13px;
      }
    }
  }

  .app-list-card {
    .inputClass {
      height: 32px !important;
      font-size: 13px;
    }
  }
}

@media (max-width: 576px) {
  .filter-card {
    .total-apps, .new-publish, .publish, .reject {
      border-bottom: 1px solid #d8dbe0 !important;
    }
  }
}

.workflow {
  .report-box {
    @media only screen and (min-width: 768px) {
      max-width: 31.33333%;
    }
    @media only screen and (max-width: 768px) {
      max-width: 49%;
    }
    @media only screen and (max-width: 529px) {
      max-width: 100%;
    }
  }

  .workflow-box {
    //margin-top: 20px;
    //margin-right: 20px;
    border-radius: 4px;
    background: #ffffff;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.15);
  }

  .create-box {
    background: #ffffff;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    @media only screen and (min-width: 768px) {
      width: 853px;
      min-height: 100px;
      //padding-right: 350px;
    }
  }

  .pull-left {
    width: 70%;
    min-height: 385px !important;
    position: relative !important;
    margin-top: 448px !important;
    border-radius: 0px !important;
    padding-bottom: 0px;
    box-shadow: 0px -4px 6px rgba(0, 0, 0, 0.16) !important;
  }

  .pull-left:before {
    content: "";
    height: 7px;
    width: 100%;
    position: absolute;
    top: -7px;
    background: inherit;
    z-index: 2;
    margin-left: -20px;
  }

  .pull-right {
    ui {
      height: 700px !important;
      overflow: auto !important;
    }
  }

  //.pull-left:after {
  //  content: "";
  //  height: 7px;
  //  width: 100%;
  //  position: absolute;
  //  bottom: -5px;
  //  background: inherit;
  //  z-index: 2;
  //  margin-left: -20px;
  //}

  .form-wrap.form-builder .stage-wrap {
    .form-field:first-child {
      margin-top: 0px !important;
    }
  }

  .divider {
    @media only screen and (min-width: 768px) {
      width: 640px;
    }
  }

  .prepend-box {
    //padding: 0px 20px;
    background: #ffffff;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
    border-radius: 8px 8px 0px 0px;
    margin-top: 36px;
    position: absolute;
    width: 100%;
    //max-width: 1200px;
    z-index: 99;
    @media only screen and (max-width: 425px) {
      margin-top: 20px !important;
      //width: 100% !important;
      .custom-width {
        width: auto !important;
      }
    }
  }

  .append-box {
    background: #ffffff;
    //height: 70px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
    border-radius: 0px 0px 8px 8px;
    margin-top: -5px;
    //position: absolute;
    //padding: 5px 20px 20px 20px;
    width: 100%;
  }
}

.del-icon:hover {
  background-color: #c66865;
}

.name-ellipsis {
  white-space: nowrap;
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
}
